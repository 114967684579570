import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldBooleanBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext, useUpdateContext } from '../../context';


export const GeneralUpdateComponent = () => {
  const {
    userGeneralIsEditing,
    userGeneralFormClose,
  } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    errorSet,
    changeFormValue,
  } = useUpdateContext();

  if (formValue === null || !userGeneralIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSumbitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={userGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Фамилия"
        name="lastName"
        error={errorSet('lastName')}
        onChange={changeFormValue}
        value={formValue.lastName}
        required
      />
      <FieldTextBlock
        label="Имя"
        name="firstName"
        error={errorSet('firstName')}
        onChange={changeFormValue}
        value={formValue.firstName}
        required
      />
      <FieldTextBlock
        label="Отчество"
        name="secondName"
        error={errorSet('secondName')}
        onChange={changeFormValue}
        value={formValue.secondName}
        required
      />
      <FieldTextBlock
        label="Электронная почта"
        name="email"
        error={errorSet('email')}
        onChange={changeFormValue}
        value={formValue.email}
        required
      />
      <FieldSelectBlock
        label="Роль"
        name="role"
        error={errorSet('role')}
        onChange={changeFormValue}
        value={formValue.role}
        items={[{ value: 'admin', label: 'Администратор' }, { value: 'Администратор', label: 'Оператор' }]}
        required
      />
      <FieldBooleanBlock
        label="Заблокирован"
        name="blocked"
        error={errorSet('blocked')}
        onChange={changeFormValue}
        value={formValue.blocked}
      />
    </FormCenteredBlock>
  );
};
