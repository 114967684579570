import React from 'react';

import {
  ListContextInterface,
  useListHook,
} from './list.hook';

const ListContext = React.createContext<ListContextInterface | undefined>(undefined);

export const useListContext = () => {
  const context = React.useContext(ListContext);

  if (context === undefined) {
    throw new Error('useListContext must be used within ListProvider');
  }

  return context;
}

export const ListProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useListHook();

  return (
    <ListContext.Provider value={api}>
      {children}
    </ListContext.Provider>
  );
};
