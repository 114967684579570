import { useNavigate } from 'react-router-dom';

import {
  WidgetLinkBrick,
  ManageAccountsIcon,
  WidgetsListBlock,
  PhoneCallbackIcon,
  PhoneSettingsIcon,
  CallListIcon,
  FormatListBulletedIcon,
} from '@app/components';
import { useAuthStore } from '@app/stores';


export const HomeFeature = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  return (
    <WidgetsListBlock>
      {user?.role === 'admin' && (
        <WidgetLinkBrick
          title="Пользователи"
          onClick={() => navigate('/user-list')}
          icon={<ManageAccountsIcon />}
        />
      )}
      <WidgetLinkBrick
        title="Звонки"
        onClick={() => navigate('/calls')}
        icon={<PhoneCallbackIcon />}
      />
      <WidgetLinkBrick
        title="Журнал звонков"
        onClick={() => navigate('/call-list-my')}
        icon={<PhoneSettingsIcon />}
      />
      <WidgetLinkBrick
        title="Пропущенные звонки"
        onClick={() => navigate('/call-list-missed')}
        icon={<CallListIcon />}
      />
      {user?.role === 'admin' && (
        <WidgetLinkBrick
          title="Общий журнал звонков"
          onClick={() => navigate('/call-list-general')}
          icon={<FormatListBulletedIcon />}
        />
      )}
    </WidgetsListBlock>
  );
};
