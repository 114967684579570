import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import {
  AdminGuardHoc,
  AnonGuardHoc,
  AuthGuardHoc,
  CallListGeneralPage,
  CallListMissedPage,
  CallListMyPage,
  CallsPage,
  HomePage,
  NotFoundPage,
  SettingsPage,
  SigninPage,
  UserCreatePage,
  UserListPage,
  UserOnePage,
} from '@app/components';


export const RouterOutletPart = () => {
  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
    { path: '/settings', element: <AuthGuardHoc><SettingsPage /></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },
    { path: '/user-list/create', element: <AuthGuardHoc><AdminGuardHoc><UserCreatePage /></AdminGuardHoc></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><AdminGuardHoc><UserOnePage /></AdminGuardHoc></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><AdminGuardHoc><UserListPage /></AdminGuardHoc></AuthGuardHoc> },
    { path: '/calls', element: <AuthGuardHoc><CallsPage /></AuthGuardHoc> },
    { path: '/call-list-general', element: <AuthGuardHoc><AdminGuardHoc><CallListGeneralPage /></AdminGuardHoc></AuthGuardHoc> },
    { path: '/call-list-my', element: <AuthGuardHoc><CallListMyPage /></AuthGuardHoc> },
    { path: '/call-list-missed', element: <AuthGuardHoc><CallListMissedPage /></AuthGuardHoc> },

    { path: '*', element: <NotFoundPage /> },
  ]);

  return (
    <RouterProvider router={router} />
  );
};