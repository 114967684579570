import React from 'react';

import {
  BackendErrorsType,
  ChangeFieldEventType,
  RoleType,
  UserType,
} from '@app/types';
import { UserApi } from '@app/api';
import { useAppStore, useAuthStore } from '@app/stores';
import { translateErrorHelper } from '@app/helpers';
import { useMainContext } from '../main';


export interface UpdateContextInterface {
  formValue: InitialFormValue | null;
  formDirty: boolean;
  formIsSumbitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

interface InitialFormValue extends UserType {
  role: RoleType;
  secondName: string;
}

export const useUpdateHook = (
): UpdateContextInterface => {
  const { notifyCall } = useAppStore();
  const { userUpdate: authUserUpdate } = useAuthStore();
  const { userOneSet, userGeneralFormClose, userGeneralIsEditing, userOne } = useMainContext();
  const [ formValue, formValueSet ] = React.useState<InitialFormValue | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSumbitting, formIsSubmittingSet ] = React.useState(false);
  
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const prepareFormValue = React.useCallback((
    user: UserType,
  ): InitialFormValue => {
    return {
      ...user,
      secondName: user.secondName || '',
    };
  }, []);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formValue === null) return;
    
    errorsSet(null);
    formIsSubmittingSet(true);
    const response = await UserApi.update(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить пользователя.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }
    
    authUserUpdate(response.data.user);
    notifyCall({
      type: 'success',
      message: 'Пользователь успешно обновлен.',
    });
    userOneSet(response.data.user);
    userGeneralFormClose();
  }, [
    formValue,
    notifyCall,
    userGeneralFormClose,
    authUserUpdate,
    userOneSet,
  ]);

  const errorSet = React.useCallback((fieldName: string): string | null => {
    return (errors && errors[fieldName]) ? translateErrorHelper(errors[fieldName][0]) : null;
  }, [
    errors,
  ]);

  React.useEffect(() => {
    if (userGeneralIsEditing && userOne !== null) {
      formValueSet(prepareFormValue(userOne))
    }
  }, [
    userGeneralIsEditing,
    userOne,
    prepareFormValue,
    formValueSet,
  ])

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
