import {
  FormFieldBrick,
  AsteriskBrick,
  TextareaBrick,
} from '@app/components';

import { FieldLongTextProps } from './field-long-text.props';


export const FieldLongTextBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
  rows = 4,
}: FieldLongTextProps) => {
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <TextareaBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
