import {
  ButtonSaveBlock,
  FieldLongTextBlock,
  FormFullwidthBlock,
} from '@app/agui/blocks';

import { ButtonsBrick } from '@app/agui/bricks';
import { Drawer } from '@mui/material'
import { ButtonConfirmComposition } from '@app/agui/compositions';
import { CloseIcon } from '@app/agui/icons';

import { useMainContext, useNoteContext } from '../../context';

export const NoteComponent = () => {
  const {
    noteDrawerOpened,
    noteDrawerClose,
  } = useMainContext();

  const {
    noteValue,
    noteDirty,
    noteSubmitting,
    noteChange,
    noteSubmit,
  } = useNoteContext();

  return (
    <Drawer
      style={{ zIndex: 2000 }}
      open={noteDrawerOpened}
      onClose={() => {}}
      anchor="right"
      hideBackdrop
    >
      <div style={{ width: '320px' }}>
        <FormFullwidthBlock
          onSubmit={noteSubmit}
          buttons={
            <ButtonsBrick vertical>
              <ButtonSaveBlock
                onClick={noteSubmit}
                disabled={!noteDirty}
                fullWidth
                loading={noteSubmitting}
              />
              <ButtonConfirmComposition
                type="button"
                buttonText="Отмена"
                onClick={noteDrawerClose}
                fullWidth
                icon={<CloseIcon />}
                message="Не сохраненные данные будут потеряны"
                zIndex={2000}
                skipConfirm={!noteDirty}
              />
            </ButtonsBrick>
          }
        >
          <FieldLongTextBlock
            value={noteValue}
            name="note"
            onChange={(e) => noteChange(e.target.value as string)}
            label="Заметка"
            rows={15}
          />
        </FormFullwidthBlock>
      </div>
    </Drawer>
  );
};
