import React from 'react';

import { PageBaseLayout } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { CallListGeneralFeature } from '@app/components';


export const CallListGeneralPage = () => {
  const {
    breadcrumbs,
    breadcrumbsCallListGeneralSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsCallListGeneralSet();
  }, [
    breadcrumbsCallListGeneralSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <CallListGeneralFeature />
    </PageBaseLayout>
  );
};
