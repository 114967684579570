import {
  ButtonBrick,
  FieldPhoneBlock,
  FieldSnilsBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useFilterContext, useMainContext } from '../../context';


export const FilterComponent = () => {
  const {
    formValue,
    changeFormValue,
    onSubmit,
  } = useFilterContext();

  const { filterReset } = useMainContext();

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={<>
        <ButtonBrick
          onClick={onSubmit}
          size="large"
          variant="contained"
        >
          Применить
        </ButtonBrick>
        <ButtonBrick
          onClick={filterReset}
          size="large"
        >
          Сбросить
        </ButtonBrick>
      </>}
    >
      <FieldTextBlock
        label="Фамилия"
        name='lastName'
        value={formValue.lastName}
        onChange={changeFormValue}
      />
      <FieldTextBlock
        label="Имя"
        name='firstName'
        value={formValue.firstName}
        onChange={changeFormValue}
      />
      <FieldTextBlock
        label="Отчество"
        name='secondName'
        value={formValue.secondName}
        onChange={changeFormValue}
      />
      <FieldSnilsBlock
        label="СНИЛС"
        name='snils'
        value={formValue.snils}
        onChange={changeFormValue}
      />
      <FieldPhoneBlock
        label="Телефон"
        name='phone'
        value={formValue.phone}
        onChange={changeFormValue}
      />
    </FormCenteredBlock>
  );
};
