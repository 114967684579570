import {
  ButtonBrick,
  ButtonsBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/agui/bricks';
import { ButtonConfirmComposition } from '@app/agui/compositions';
import { useCallContext, useListContext } from '../../context';

export const ListComponent = () => {
  const {
    callStart,
  } = useCallContext();
  
  const {
    callerList,
    callReject,
  } = useListContext();

  return (
    <TableContainerBrick>
        <TableBrick aria-label="roles">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>Очередь</TableCellBrick>
              <TableCellBrick>Фамилия</TableCellBrick>
              <TableCellBrick>Имя</TableCellBrick>
              <TableCellBrick>Отчество</TableCellBrick>
              <TableCellBrick>Телефон</TableCellBrick>
              <TableCellBrick>СНИЛС</TableCellBrick>
              <TableCellBrick></TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {callerList.map((item, index) => (
              <TableRowBrick
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.clientId}
              >
                <TableCellBrick component="th" scope="row">
                  {index + 1}
                </TableCellBrick>
                <TableCellBrick>{item.lastName}</TableCellBrick>
                <TableCellBrick>{item.firstName}</TableCellBrick>
                <TableCellBrick>{item.secondName}</TableCellBrick>
                <TableCellBrick>{item.phone}</TableCellBrick>
                <TableCellBrick>{item.snils}</TableCellBrick>
                <TableCellBrick>
                  <ButtonsBrick>
                    <ButtonBrick
                      size="small"
                      onClick={() => callStart(item.id)}
                    >
                      Принять без очереди
                    </ButtonBrick>
                    <ButtonConfirmComposition
                      onClick={() => callReject(item.id)}
                      message="Текущий звонок будет удален из очереди."
                      size="small"
                      type="button"
                      buttonText="Отклонить"
                    />
                  </ButtonsBrick>
                  </TableCellBrick>
              </TableRowBrick>
            ))}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
  );
};
