import React from 'react';

import {
  MainContextInterface,
  useMainHook,
} from './main.hook';
import { MainProps } from './main.props';


const MainContext = React.createContext<MainContextInterface | undefined>(undefined);

export const useMainContext = () => {
  const context = React.useContext(MainContext);

  if (context === undefined) {
    throw new Error('useMainContext must be used within MainProvider');
  }

  return context;
}

export const MainProvider = ({
  call,
  closeModal,
  callUpdate,
  children,
}: MainProps) => {
  const api = useMainHook(
    call,
    closeModal,
    callUpdate,
  );
  
  return (
    <MainContext.Provider value={api}>
      {children}
    </MainContext.Provider>
  )
}
