import {
  ButtonBrick,
  ButtonIconBrick,
  CloseIcon,
} from '@app/components';

import { ButtonCancelProps } from './button-cancel.props';


export const ButtonCancelBlock = ({
  onClick,
  label,
  variant = 'outlined',
  buttonIcon,
  fullWidth,
}: ButtonCancelProps) => {
  if (buttonIcon) return (
    <ButtonIconBrick
      onClick={onClick}
    >
      <CloseIcon />
    </ButtonIconBrick>
  );

  return (
    <ButtonBrick
      onClick={onClick}
      size="large"
      startIcon={<CloseIcon />}
      variant={variant}
      fullWidth={fullWidth}
    >
      {label || 'Отмена'}
    </ButtonBrick>
  );
};
