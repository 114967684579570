import {
  ButtonLoadingBrick,
  CardDataComposition,
  FormFullwidthBlock,
  FieldPasswordBlock,
  FieldTextBlock,
} from '@app/components';

import styles from './main.module.scss';
import { useMainContext } from '../../context';

export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  } = useMainContext();

  return (
    <div className={styles['main']}>
      <CardDataComposition
        title="Авторизация"
      >
        <FormFullwidthBlock
          onSubmit={onSubmit}
          buttons={
            <ButtonLoadingBrick
              onClick={onSubmit}
              disabled={!formDirty}
              loading={formIsSumbitting}
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Авторизация
            </ButtonLoadingBrick>
          }
        >
            <FieldTextBlock
              label="Электронная почта"
              value={formValue.email}
              onChange={changeFormValue}
              name="email"
              error={errorSet('email')}
            />

            <FieldPasswordBlock
              label="Пароль"
              value={formValue.password}
              onChange={changeFormValue}
              name="password"
              error={errorSet('password')}
            />
        </FormFullwidthBlock>
      </CardDataComposition>
    </div>
  );
};
