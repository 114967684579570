import React from 'react';

import {
  FilterContextInterface,
  useFilterHook,
} from './filter.hook';

const FilterContext = React.createContext<FilterContextInterface | undefined>(undefined);

export const useFilterContext = () => {
  const context = React.useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterContext must be used within FilterProvider');
  }

  return context;
}

export const FilterProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useFilterHook();

  return (
    <FilterContext.Provider value={api}>
      {children}
    </FilterContext.Provider>
  );
};
