import {
  ButtonBrick,
  ButtonsBrick,
  CardBrick,
  DialogBrick,
  DividerHorizontalBrick,
  TypographyBrick,
} from "@app/agui/bricks";

import {
  ButtonConfirmComposition,
  ToolbarComposition,
} from "@app/agui/compositions";

import { FieldSelectBlock } from "@app/agui/blocks";

import { useCallContext, useDevicesContext, useMainContext } from "../../context";
import { NoteComponent } from '../note';

export const CallComponent = () => {
  const {
    callModalOpened,
    callerCurrent,
    noteDrawerOpen,
  } = useMainContext();

  const {
    cameraMuted,
    microphoneMuted,
    cameraDeviceId,
    microphoneDeviceId,
    cameraList,
    microphoneList,
    cameraSet,
    microphoneSet,
    cameraToggle,
    microphoneToggle,
  } = useDevicesContext();

  const {
    operatorCallStop,
    provideMediaRef,
  } = useCallContext();

  return (
    <DialogBrick
      opened={callModalOpened}
      onClose={() => {}}
    >
      <ToolbarComposition>
        <TypographyBrick variant="h6">Звонок</TypographyBrick>
      </ToolbarComposition>
      <div className="container" style={{ marginTop: '24px' }}>
        <div className="row">
          <div className="col">
            <CardBrick>
              <div className="container-fluid">

              <div className="row">
                <div className="col-sm-9">
                  <video
                    id="caller-video"
                    width='100%'
                    autoPlay
                    playsInline
                    style={{ marginTop: '12px', borderRadius: '4px', maxHeight: '600px' }}
                    ref={(node) => {
                      provideMediaRef('remote', node);
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <video
                    id="operator-video"
                    width='100%'
                    autoPlay
                    playsInline
                    muted={true}
                    style={{ marginTop: '12px', borderRadius: '4px' }}
                    ref={(node) => {
                      provideMediaRef('local', node);
                    }}
                  />
                  {callerCurrent && (
                    <TypographyBrick
                      style={{
                        textAlign: 'center',
                        marginBottom: '6px',
                        marginTop: '6px',
                      }}
                    >
                      {callerCurrent.lastName} {callerCurrent.firstName} {callerCurrent.secondName}
                    </TypographyBrick>
                  )}
                  <DividerHorizontalBrick />
                  <div style={{ padding: '6px 0 0' }}>
                    <ButtonsBrick vertical>
                      <FieldSelectBlock
                        name="camera"
                        value={cameraDeviceId}
                        onChange={(e) => cameraSet(e.target.value as string)}
                        items={cameraList.map((camera) => ({ value: camera.deviceId, label: camera.label }))}
                        label="Камера"
                      />
                      <FieldSelectBlock
                        name="microphone"
                        value={microphoneDeviceId}
                        onChange={(e) => microphoneSet(e.target.value as string)}
                        items={microphoneList.map((microphone) => ({ value: microphone.deviceId, label: microphone.label }))}
                        label="Микрофон"
                      />
                      <ButtonBrick
                        onClick={cameraToggle}
                        variant={cameraMuted ? 'contained' : 'outlined'}
                        fullWidth
                        size="large"
                      >
                        {cameraMuted ? 'Включить камеру' : 'Выключить камеру'}
                      </ButtonBrick>
                      <ButtonBrick
                        onClick={microphoneToggle}
                        variant={microphoneMuted ? 'contained' : 'outlined'}
                        fullWidth
                        size="large"
                      >
                        {microphoneMuted ? 'Включить микрофон' : 'Выключить микрофон'}
                      </ButtonBrick>
                      <ButtonBrick
                        onClick={noteDrawerOpen}
                        size="large"
                        fullWidth
                      >
                        Заметка
                      </ButtonBrick>
                      <ButtonConfirmComposition
                        type="button"
                        variant="contained"
                        onClick={() => operatorCallStop()}
                        message="Завершить звонок?"
                        buttonText="Завершить звонок"
                        fullWidth
                      />
                    </ButtonsBrick>
                  </div>
                </div>
              </div>
              </div>
            </CardBrick>
          </div>
        </div>
      </div>

      <NoteComponent />
    </DialogBrick>
  );
};
