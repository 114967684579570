import React from 'react';

import {
  ChangeFieldEventType,
} from '@app/types';

import { useMainContext } from '../main';


const initialFormValue = {
  lastName: '',
  firstName: '',
  secondName: '',
  snils: '',
  phone: '',
};

export interface FilterContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => void;
};


export const useFilterHook = (
): FilterContextInterface => {
  const { listDownload, filterClose, filterPayload } = useMainContext();
  
  const [ formValue, formValueSet ] = React.useState(filterPayload as typeof initialFormValue || initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback((e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    filterClose();
    
    listDownload(formValue);
  }, [
    formValue,
    filterClose,
    listDownload,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
  }), [
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
  ]);
};
