import { CallStatusType } from "@app/types";


export const translateStatusHelper = (status: CallStatusType): string => {
  if (status === 'pending') return 'Ожидает';
  if (status === 'in-flight') return 'Звонок';
  if (status === 'done') return 'Завершен';
  if (status === 'not-processed') return 'Не обработан';
  if (status === 'rejected') return 'Отклонен';
  
  return status;
}
