import {
  SaveIcon,
  ButtonLoadingBrick,
} from '@app/components';

import { ButtonSaveProps } from './button-save.props';


export const ButtonSaveBlock = ({
  onClick,
  loading,
  disabled,
  size,
  variant,
  fullWidth,
}: ButtonSaveProps) => {
  return (
    <ButtonLoadingBrick
      loading={loading}
      type="submit"
      onClick={onClick}
      size={size || 'large'}
      startIcon={<SaveIcon />}
      variant={variant || 'contained'}
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
    >
      Сохранить
    </ButtonLoadingBrick>
  );
};
