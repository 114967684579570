export const ACTIONS = {
  // LISTEN
  DATA_GENERAL_SEND: 'DATA_GENERAL_SEND',
  DATA_TO_CALLER_SEND: 'DATA_TO_CALLER_SEND',
  CALLER_LIST_TO_OPERATOR_SEND: 'CALLER_LIST_TO_OPERATOR_SEND',
  CALLER_TO_OPERATOR_SEND: 'CALLER_TO_OPERATOR_SEND',
  CALLER_CALL_STOP: 'CALLER_CALL_STOP',
  ANSWER_TO_OPERATOR_SEND: 'ANSWER_TO_OPERATOR_SEND',
  ICE_CANDIDATE: 'ICE_CANDIDATE',
  CLIENT_DISCONNECT: 'CLIENT_DISCONNECT',

  // EMIT
  OPERATOR_CONNECT: 'OPERATOR_CONNECT',
  OPERATOR_DISCONNECT: 'OPERATOR_DISCONNECT',
  OPERATOR_CALL_START: 'OPERATOR_CALL_START',
  OPERATOR_CALL_STOP: 'OPERATOR_CALL_STOP',
  OPERATOR_CALL_REJECT: 'OPERATOR_CALL_REJECT',
  OPERATOR_SEND_OFFER: 'OPERATOR_SEND_OFFER',
  RELAY_ICE: 'RELAY_ICE',
};
