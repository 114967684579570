import React from 'react';

import {
  CallContextInterface,
  useCallHook,
} from './call.hook';

const CallContext = React.createContext<CallContextInterface | undefined>(undefined);

export const useCallContext = () => {
  const context = React.useContext(CallContext);

  if (context === undefined) {
    throw new Error('useCallContext must be used within CallProvider');
  }

  return context;
}

export const CallProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useCallHook();

  return (
    <CallContext.Provider value={api}>
      {children}
    </CallContext.Provider>
  );
};
