import React from 'react';

import { CallListMyFeature, PageBaseLayout } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const CallListMyPage = () => {
  const {
    breadcrumbs,
    breadcrumbsCallListMySet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsCallListMySet();
  }, [
    breadcrumbsCallListMySet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <CallListMyFeature />
    </PageBaseLayout>
  );
};
