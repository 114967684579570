import {
  CardActionsBrick,
  DividerHorizontalBrick,
  LinkBrick,
  PaginationBlock,
  ScrollXZoneBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from "@app/components";

import { translateDatetimeHelper, translateStatusHelper } from "@app/helpers";
import { useMainContext } from "../../context";


export const ListComponent = () => {
  const {
    list,
    page,
    pages,
    pageSelect,
    callModalOpen,
  } = useMainContext();

  return (
    <>
      <ScrollXZoneBrick>
        <TableContainerBrick>
          <TableBrick aria-label="roles">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>ID</TableCellBrick>
                <TableCellBrick>Фамилия</TableCellBrick>
                <TableCellBrick>Имя</TableCellBrick>
                <TableCellBrick>Отчество</TableCellBrick>
                <TableCellBrick>Телефон</TableCellBrick>
                <TableCellBrick>СНИЛС</TableCellBrick>
                <TableCellBrick>Статус</TableCellBrick>
                <TableCellBrick>Оператор</TableCellBrick>
                <TableCellBrick>Создано</TableCellBrick>
                <TableCellBrick>Обработано</TableCellBrick>
                <TableCellBrick>Завершено</TableCellBrick>
                <TableCellBrick></TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {list.map((item) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  <TableCellBrick component="th" scope="row">
                    {item.id}
                  </TableCellBrick>
                  <TableCellBrick>{item.lastName}</TableCellBrick>
                  <TableCellBrick>{item.firstName}</TableCellBrick>
                  <TableCellBrick>{item.secondName}</TableCellBrick>
                  <TableCellBrick>{item.phone}</TableCellBrick>
                  <TableCellBrick>{item.snils}</TableCellBrick>
                  <TableCellBrick>{translateStatusHelper(item.status)}</TableCellBrick>
                  <TableCellBrick>{item.operator ? (item.operator.firstName + ' ' + item.operator.lastName) : '---'}</TableCellBrick>
                  <TableCellBrick>{translateDatetimeHelper(item.createdAt)}</TableCellBrick>
                  <TableCellBrick>{translateDatetimeHelper(item.callStartDate)}</TableCellBrick>
                  <TableCellBrick>{translateDatetimeHelper(item.callEndDate)}</TableCellBrick>
                  <TableCellBrick><LinkBrick onClick={() => callModalOpen(item)} label="Подробнее" /></TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      </ScrollXZoneBrick>
      
      {pages > 1 && (<>
        <DividerHorizontalBrick />
        <CardActionsBrick>
          <PaginationBlock
            current={page}
            count={pages}
            setCurrent={pageSelect}
          />
        </CardActionsBrick>
      </>)}
    </>
  );
};
