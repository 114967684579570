import React from 'react';

import {
  NoteContextInterface,
  useNoteHook,
} from './note.hook';

const NoteContext = React.createContext<NoteContextInterface | undefined>(undefined);

export const useNoteContext = () => {
  const context = React.useContext(NoteContext);

  if (context === undefined) {
    throw new Error('useNoteContext must be used within NoteProvider');
  }

  return context;
}

export const NoteProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useNoteHook();

  return (
    <NoteContext.Provider value={api}>
      {children}
    </NoteContext.Provider>
  );
};
