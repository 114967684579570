import {
  ButtonBrick,
  FieldPhoneBlock,
  FieldSelectBlock,
  FieldSnilsBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useFilterContext, useMainContext } from '../../context';


export const FilterComponent = () => {
  const {
    formValue,
    operatorList,
    changeFormValue,
    onSubmit,
  } = useFilterContext();

  const { filterReset } = useMainContext();

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={<>
        <ButtonBrick
          onClick={onSubmit}
          size="large"
          variant="contained"
        >
          Применить
        </ButtonBrick>
        <ButtonBrick
          onClick={filterReset}
          size="large"
        >
          Сбросить
        </ButtonBrick>
      </>}
    >
      <FieldTextBlock
        label="Фамилия"
        name='lastName'
        value={formValue.lastName}
        onChange={changeFormValue}
      />
      <FieldTextBlock
        label="Имя"
        name='firstName'
        value={formValue.firstName}
        onChange={changeFormValue}
      />
      <FieldTextBlock
        label="Отчество"
        name='secondName'
        value={formValue.secondName}
        onChange={changeFormValue}
      />
      <FieldSnilsBlock
        label="СНИЛС"
        name='snils'
        value={formValue.snils}
        onChange={changeFormValue}
      />
      <FieldPhoneBlock
        label="Телефон"
        name='phone'
        value={formValue.phone}
        onChange={changeFormValue}
      />
      <FieldSelectBlock
        label="Статус"
        name='status'
        value={formValue.status || null}
        onChange={changeFormValue}
        items={[
          { value: 'pending', label: 'Ожидание' },
          { value: 'in-flight', label: 'Звонок' },
          { value: 'done', label: 'Завершен' },
          { value: 'not-processed', label: 'Не обработан' },
          { value: 'rejected', label: 'Отклонен' },
        ]}
      />
      <FieldSelectBlock
        label="Оператор"
        name='operatorId'
        value={formValue.operatorId || null}
        onChange={changeFormValue}
        items={operatorList.items}
      />
    </FormCenteredBlock>
  );
};
