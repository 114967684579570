import { ButtonsBrick, DialogBrick } from '@app/agui/bricks';
import { ButtonConfirmComposition, CardDataComposition } from '@app/agui/compositions';
import { ButtonSaveBlock, FieldLongTextBlock, FormFullwidthBlock } from '@app/agui/blocks';
import { CloseIcon } from '@app/agui/icons';

import { useMainContext } from '../../context';


export const NoteComponent = () => {
  const {
    call,
    noteValue,
    noteDirty,
    noteSubmitting,
    noteChange,
    cancelHandler,
    saveHandler,
  } = useMainContext();

  return(
    <DialogBrick
      onClose={noteDirty ? () => {} : cancelHandler}
      opened={call !== null}
      size="medium"     
    >
      <CardDataComposition
        title={call?.lastName + ' ' + call?.firstName + ' ' + call?.secondName}
      >
        <FormFullwidthBlock
          onSubmit={saveHandler}
          buttons={
            <ButtonsBrick>
              <ButtonSaveBlock
                onClick={saveHandler}
                disabled={!noteDirty}
                loading={noteSubmitting}
              />
              <ButtonConfirmComposition
                type="button"
                buttonText="Отмена"
                onClick={cancelHandler}
                icon={<CloseIcon />}
                message="Не сохраненные данные будут потеряны"
                skipConfirm={!noteDirty}
              />
            </ButtonsBrick>
          }
        >
          <FieldLongTextBlock
            name="note"
            label="Заметка"
            onChange={(e) => noteChange(e.target.value as string)}
            value={noteValue}
            rows={10}
          />
        </FormFullwidthBlock>
      </CardDataComposition>
    </DialogBrick>
  );
};
