import React from 'react';

import { CallListMissedFeature, PageBaseLayout } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const CallListMissedPage = () => {
  const {
    breadcrumbs,
    breadcrumbsCallListMissedSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsCallListMissedSet();
  }, [
    breadcrumbsCallListMissedSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <CallListMissedFeature />
    </PageBaseLayout>
  );
};
