import React from 'react';

import {
  DevicesContextInterface,
  useDevicesHook,
} from './devices.hook';

const DevicesContext = React.createContext<DevicesContextInterface | undefined>(undefined);

export const useDevicesContext = () => {
  const context = React.useContext(DevicesContext);

  if (context === undefined) {
    throw new Error('useDevicesContext must be used within DevicesProvider');
  }

  return context;
}

export const DevicesProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useDevicesHook();

  return (
    <DevicesContext.Provider value={api}>
      {children}
    </DevicesContext.Provider>
  );
};
