import React from 'react';

import { useBreadcrumbsStore } from '@app/stores';
import { CallsFeature, PageBaseLayout } from '@app/components';


export const CallsPage = () => {
  const {
    breadcrumbs,
    breadcrumbsCallsSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsCallsSet();
  }, [
    breadcrumbsCallsSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <CallsFeature />
    </PageBaseLayout>
  );
};
