import * as React from 'react';

import { useAppStore } from '@app/stores';
import { CallType } from '@app/types';
import { CallApi } from '@app/api';


export type MainContextInterface = {
  call: CallType | null;
  noteValue: string;
  noteDirty: boolean;
  noteSubmitting: boolean;
  noteChange: (note: string) => void;
  cancelHandler: () => void;
  saveHandler: (e: React.SyntheticEvent) => void;
}

export const useMainHook = (
  call: CallType | null,
  closeModal: () => void,
  callUpdate: (call: CallType) => void,
): MainContextInterface => {
  const { notifyCall } = useAppStore();

  const [ noteValue, noteValueSet ] = React.useState('');
  const [ noteDirty, noteDirtySet ] = React.useState(false);
  const [ noteSubmitting, noteSubmittingSet ] = React.useState(false);

  const noteChange = React.useCallback((note: string) => {
    noteValueSet(note);
    noteDirtySet(true);
  }, []);

  const cancelHandler = React.useCallback(() => {
    if (call === null) return;

    noteValueSet(call.note);
    noteDirtySet(false);
    closeModal();
  }, [
    call,
    closeModal,
  ]);

  const saveHandler = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (call === null) return;

    noteSubmittingSet(true);
    const response = await CallApi.noteSet({
      callId: call.id,
      note: noteValue,
    });
    noteSubmittingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось сохранить заметку',
      });
    };

    notifyCall({
      type: 'success',
      message: 'Данные успешно обновлены',
    });

    callUpdate(response.data.call);
    noteDirtySet(false);
    closeModal();
  }, [
    call,
    noteValue,
    callUpdate,
    notifyCall,
    closeModal,
  ]);

  React.useEffect(() => {
    if (call !== null) noteValueSet(call.note || '');
    else noteValueSet('');
  }, [
    call,
  ]);

  return React.useMemo(() => ({
    call,
    noteValue,
    noteDirty,
    noteSubmitting,
    cancelHandler,
    noteChange,
    saveHandler,
  }), [
    call,
    noteValue,
    noteDirty,
    noteSubmitting,
    cancelHandler,
    noteChange,
    saveHandler,
  ]);
}
