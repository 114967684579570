export const translateDatetimeHelper = (date: Date | undefined | null): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const buildValue = (value: number): string => {
    return value < 10 ? '0' + value : String(value)
  }

  return `${buildValue(day)}.${buildValue(month)}.${year} ${buildValue(hours)}:${buildValue(minutes)}`;
}
