import { MainComponent } from './components';
import { MainProvider } from './context';
import { NoteProps } from './note.props';


export const NoteSmart = ({
  call,
  closeModal,
  callUpdate,
}: NoteProps) => {
  return (
    <MainProvider
      call={call}
      closeModal={closeModal}
      callUpdate={callUpdate}
    >
      <MainComponent />
    </MainProvider>
  );
};
