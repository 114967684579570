import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  CallListGeneralRequest,
  CallListGeneralResponse,
  CallListMissedRequest,
  CallListMissedResponse,
  CallListMyRequest,
  CallListMyResponse,
  CallNoteSetRequest,
  CallNoteSetResponse,
} from './types';


export class CallApi {
  public static async listGeneral(
    payload: CallListGeneralRequest,
  ): Promise<BaseResponseInterface<CallListGeneralResponse>> {
    return await HttpService.sendRequest('GET', '/calls/list-general', payload);
  };

  public static async listMy(
    payload: CallListMyRequest,
  ): Promise<BaseResponseInterface<CallListMyResponse>> {
    return await HttpService.sendRequest('GET', '/calls/list-my', payload);
  };

  public static async listMissed(
    payload: CallListMissedRequest,
  ): Promise<BaseResponseInterface<CallListMissedResponse>> {
    return await HttpService.sendRequest('GET', '/calls/list-missed', payload);
  };

  public static async noteSet(
    payload: CallNoteSetRequest,
  ): Promise<BaseResponseInterface<CallNoteSetResponse>> {
    return await HttpService.sendRequest('POST', '/calls/note-set', payload);
  }
};
