import React from 'react';

import { useMainContext } from '../main';
import { CallApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface NoteContextInterface {
  noteValue: string;
  noteDirty: boolean;
  noteSubmitting: boolean;
  noteChange: (note: string) => void;
  noteSubmit: (e?: React.SyntheticEvent) => void;
  onDisconnect: () => void;
};

export const useNoteHook = (
): NoteContextInterface => {
  const { notifyCall } = useAppStore();

  const {
    callerCurrent,
    callerCurrentSet,
    noteDrawerClose,
  } = useMainContext();

  const [ noteValue, noteValueSet ] = React.useState('');
  const [ noteDirty, noteDirtySet ] = React.useState(false);
  const [ noteSubmitting, noteSubmittingSet ] = React.useState(false);

  const noteChange = React.useCallback((note: string) => {
    noteValueSet(note);
    noteDirtySet(true);
  }, []);
  
  const noteSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (callerCurrent === null) return;

    noteSubmittingSet(true);
    const response = await CallApi.noteSet({
      callId: callerCurrent.id,
      note: noteValue,
    });
    noteSubmittingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось сохранить заметку',
      });
    };

    callerCurrentSet(response.data.call);
    noteDrawerClose();
    noteDirtySet(false);
  }, [
    noteValue,
    callerCurrent,
    noteDrawerClose,
    notifyCall,
    callerCurrentSet,
  ]);

  const onDisconnect = React.useCallback(async () => {
    if (noteDirty) await noteSubmit();
  }, [
    noteDirty,
    noteSubmit,
  ]);

  React.useEffect(() => {
    if (callerCurrent !== null && callerCurrent.note) noteValueSet(callerCurrent.note);
  }, [
    callerCurrent,
  ]);

  return React.useMemo(() => ({
    noteValue,
    noteDirty,
    noteSubmitting,
    noteChange,
    noteSubmit,
    onDisconnect,
  }), [
    noteValue,
    noteDirty,
    noteSubmitting,
    noteChange,
    noteSubmit,
    onDisconnect,
  ]);
};
